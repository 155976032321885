import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-a0f26442"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "svg-icon",
  "aria-hidden": "true"
};
var _hoisted_2 = ["xlink:href"];
import { computed } from 'vue';
export default {
  __name: 'index',
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var iconName = computed(function () {
      return "#icon-".concat(props.icon);
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("svg", _hoisted_1, [_createElementVNode("use", {
        "xlink:href": iconName.value
      }, null, 8, _hoisted_2)]);
    };
  }
};