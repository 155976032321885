import { createStore } from 'vuex'

export default createStore({
  state: {
    app: {
      token: localStorage.getItem('token') // 从 localStorage 获取 token
    }
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {}
})
